<template>
  <div class="page">
    <v-snackbar
      v-model="$store.getters.noRoutes"
      color="primary"
      :multi-line="true"
    >
      <span style="font-weight: bold; font-size: 16px">
        {{ $t("noRoutes") }}</span
      >

      <!-- change -->

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="$store.dispatch('setNoRoutes', false)"
          outlined
        >
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="$store.getters.routerLoader"
      color="primary"
      :multi-line="true"
    >
      <div class="d-flex flex-row justify-content-center">
        <span style="font-weight: bold; font-size: 16px">
          {{ $t("routeLoaderText") }}...</span
        >
        <v-progress-circular
          :size="20"
          color="#fff"
          indeterminate
          class="ml-2"
        ></v-progress-circular>
      </div>

      <!-- change -->
    </v-snackbar>

    <Map
      v-if="$store.getters.accessToken"
      :centerPoint="center"
      :floor="floor"
      :route="route"
      :zoom="zoom"
      :clustersList="clustersList"
      :showClusters="showClusters"
      :stepper="stepper"
      :center="center"
      :placeTitle="$store.getters.title"
      :searchDestination="$store.getters.searchDestination"
      :shopInfo="$store.getters.shopInfo"
      :mapStyleUrl="mapStyleUrl"
      :accessToken="$store.getters.accessToken"
      :placeLoading="placeLoading"
      :disabled="disabled"
    />

    <SettingsButton
      v-if="
        (this.placeConfig.disabilityButton &&
          this.placeConfig.disabilityButton.position !== 'top-right') ||
        this.placeConfig.disabilityButton === undefined
      "
    />
    <GiftButton />
    <ParkingButton />

    <FloorButtons :routeInfo="routeInfo" :showClusters="showClusters" />
    <RouteSnackbar
      :routeInfo="routeInfo"
      :route="route"
      :key="routeInfo.length"
    />
    <RouteWarning
      v-if="route"
      :routeInfo="routeInfo"
      :key="routeInfo.length + 1"
    />

    <GlobalLoader />
  </div>
</template>

<script>
import FloorButtons from "@/components/FloorButtons.vue";
import GiftButton from "@/components/GiftButton.vue";
import ParkingButton from "@/components/ParkingButton.vue";
import SettingsButton from "@/components/SettingsButton.vue";
import Map from "@/components/Map.vue";
import RouteSnackbar from "../components/RouteSnackbar.vue";

import RouteWarning from "../components/RouteWarning.vue";
import GlobalLoader from "../components/GlobalLoader.vue";

export default {
  name: "Home",
  components: {
    FloorButtons,
    GiftButton,
    SettingsButton,
    Map,
    ParkingButton,
    RouteSnackbar,
    RouteWarning,
    GlobalLoader,
  },
  data() {
    return {};
  },
  computed: {
    floor() {
      //Map floor state
      return this.$store.getters.floor;
    },
    center() {
      //Map center point
      return this.$store.getters.center;
    },
    route() {
      //Route coordinates for drawing
      return this.$store.getters.drawRoute;
    },

    zoom() {
      //Map zoom level
      return this.$store.getters.zoom;
    },
    routeInfo() {
      //Route info for giving route information
      return this.$store.getters.routeInfo;
    },

    stepper() {
      //User icon moving step state
      return this.$store.getters.stepper;
    },
    loading() {
      return this.$store.getters.loading;
    },
    clustersList() {
      return this.$store.getters.clustersList;
    },
    showClusters() {
      return this.$store.getters.showClusters;
    },
    shopInfo() {
      return this.$store.getters.shopInfo;
    },
    mapStyleUrl() {
      return this.$store.getters.styleUrl;
    },
    placeLoading() {
      return this.$store.getters.placesLoading;
    },
    placeConfig() {
      return this.$store.getters.placeConfig;
    },
    disabled() {
      return localStorage.getItem("disabled");
    },
  },

  mounted() {
    if (this.placeConfig.css) {
      this.applyStyles(this.placeConfig.css);
    }
  },
  methods: {
    applyStyles(styleContent) {
      const styleElement = document.createElement("style");
      styleElement.type = "text/css";
      styleElement.appendChild(document.createTextNode(styleContent));
      document.head.appendChild(styleElement);
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 99vh;
}
</style>
